<template>
    <div>
        <!--
            `.overflow-hidden` is used here to prevent the blue background going out
            of the `.card`. We are not putting this style in `_card.scss` because
            in some cases there might be a dropdown inside a `.card`.
        -->
        <div class="card overflow-hidden">
            <match-versus
                :match-versus="teamMatch.toMatchVersus(scoreEditable)"
                class="cursor-pointer"
                team-class="py-2"
                name-class="text-uppercase font-weight-bold"
                :variant="containsIncorrectMatches ? 'danger' : 'primary'"
                @click.native="toggleCollapse()"
            />

            <b-collapse
                v-model="collapseIsOpen"
            >
                <wait-for-resource
                    :resource="schedules"
                    class="border-top"
                    :class="{'py-3': schedules.loading || schedules.fatal}"
                >
                    <div
                        v-if="!schedulesWithTeamUsers[0]"
                        class="text-center py-3"
                    >
                        {{ $t('common.messages.players_not_announced') }}
                    </div>

                    <tms-versus
                        v-for="(schedule, si) in schedulesWithTeamUsers"
                        v-else
                        :key="`s-${si}`"
                        :class="{'border-top': si > 0 }"
                        :schedule="schedule"
                        :team-match="teamMatch"
                        :score-editable="scoreEditable"
                        :variant="matchIsIncorrectOrLate(schedule) ? 'danger' : 'primary'"
                    />
                </wait-for-resource>
            </b-collapse>
        </div>

        <!-- Verify Scores Button -->
        <div
            v-if="scoreEditable && collapseIsOpen && !!schedulesWithTeamUsers[0]"
            class="text-right mt-3 mb-4"
        >
            <btn-resource
                :resource="teamMatch"
                class="btn-primary btn-min-w"
                @click="verifyScores()"
            >
                {{ $t('common.actions.verify_scores') }}
            </btn-resource>
        </div>
    </div>
</template>

<script>
import {TeamMatch} from '@/models/TeamMatch';
import MatchVersus from '@/components/common/MatchVersus';
import {TeamMatchSchedules} from '@/models/TeamMatchSchedule';
import TeamMatchStatus from '@/library/enums/TeamMatchStatus';
import TmsVersus from '@/components/teamMatchSchedules/Versus';
import TmsStatus from '@/library/enums/TeamMatchScheduleStatus';

/**
 * Versus representation of a TeamMatch instance. A click on the <match-versus> component
 * will fetch and show the schedules and match scores (if played) of the TeamMatch.
 */
export default {
    name: 'TeamMatchVersus',
    components: {MatchVersus, TmsVersus},
    props: {
        teamMatch: {
            type: TeamMatch,
            required: true,
        },

        /**
         * The date on which the team match is expected to be played.
         */
        expectedPlayedAt: {
            type: Object,
            default: undefined,
        },

        /**
         * If true, the match score of the team match schedules can be edited.
         */
        scoreEditable: {
            type: Boolean,
            default: false,
        },

        containsIncorrectMatches: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            /**
             * Indicates whether the team match schedules is being shown or not.
             */
            collapseIsOpen: false,
            schedules: new TeamMatchSchedules([], {
                routePrefix: this.teamMatch.getFetchURL(),
            }),
        };
    },
    computed: {
        /**
         * An array of schedules that have team users assigned to it.
         *
         * @return {TeamMatchSchedules[]}
         */
        schedulesWithTeamUsers() {
            return this.schedules.where(schedule => !schedule.team_users.isEmpty());
        },
    },
    methods: {
        async toggleCollapse() {
            this.collapseIsOpen = !this.collapseIsOpen;

            if (this.collapseIsOpen && this.schedules.isEmpty()) {
                await this.schedules.fetch();

                // Link the fetched schedules to the team match, so the team match can be
                // saved with the edited match scores.
                this.teamMatch.team_match_schedules = this.schedules;
            }
        },

        async verifyScores() {
            const data = {
                team_match_schedules: this.teamMatch.team_match_schedules.map((tms) => {
                    const match = {
                        winning_score: tms.match.winning_score,
                        holes_not_played: tms.match.holes_not_played,
                        winning_team_id: tms.winning_team_id,
                    };

                    return {
                        id: tms.id,
                        match,
                    };
                }),
            };

            await this.teamMatch.save({data});

            this.$emit('scores-verified');

            // Update the schedules with the ones from the saved team match,
            // so the latest data from API is displayed.
            this.schedules = this.teamMatch.team_match_schedules;
        },

        /**
         * Returns true if the schedule is still `PLAYABLE`, but the `expectedPlayedAt`
         * has passed.
         *
         * @return {Boolean}
         */
        matchIsPostedLate(schedule) {
            // If the `expectedPlayedAt` is not provided, we assume that the match is
            // not posted late.
            if (!this.expectedPlayedAt) return false;

            return schedule.status === TmsStatus.PLAYABLE
                && this.$moment().isAfter(this.expectedPlayedAt, 'day');
        },

        /**
         * Returns true if the schedule's match has been reported as incorrect or is
         * posted late.
         *
         * @param  {TeamMatchSchedule} schedule
         * @return {boolean}
         */
        matchIsIncorrectOrLate(schedule) {
            return schedule.match_is_incorrect || this.matchIsPostedLate(schedule);
        },
    },
};
</script>

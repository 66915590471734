<template>
    <div>
        <b-row
            class="position-relative"
            no-gutters
        >
            <!--
            If the match is draw, we color both teams blue or red. This is done
            to differentiate between played "draw" match and unplayed one.
            -->
            <b-col
                v-for="(t, ti) in matchVersus.teams"
                :key="`t-${ti}`"
                cols="6"
                :class="[
                    {'border-left': imageSide(ti) === 'right'},
                    matchVersus.isDraw || t.isWinner ? `bg-${variant} text-white` : '',
                    teamClass,
                ]"
            >
                <div
                    v-for="(p, pi) in t.participants"
                    :key="`p-${pi}`"
                    class="py-2 px-3"
                >
                    <b-row align-v="center">
                        <!-- Participant's Image (Hidden in mobile) -->
                        <b-col
                            class="d-none d-sm-block"
                            cols="auto"
                            :order="imageSide(ti) === 'left' ? 0 : 2"
                        >
                            <ow-img
                                class="img-table rounded-circle border border-white"
                                src-key="image"
                                :src="p"
                            />
                        </b-col>

                        <!-- Participant's Name -->
                        <b-col
                            class="px-2 px-md-3"
                            :class="[`text-${imageSide(ti)}`, 'text-truncate', nameClass]"
                            order="1"
                        >
                            {{ p.name }}
                        </b-col>

                        <!-- Team's Score (Best used when there is only 1 participant in each team) -->
                        <b-col
                            v-if="t.score != null"
                            cols="auto"
                            class="px-2 px-md-3"
                            :order="imageSide(ti) === 'left' ? 2 : 0"
                        >
                            <h5 class="font-weight-bold mb-0">
                                {{ t.score }}
                            </h5>
                        </b-col>
                    </b-row>
                </div>
            </b-col>

            <div class="position-absolute-center">
                <slot name="absolute-center" />
            </div>
        </b-row>
    </div>
</template>

<script>
import {MatchVersus} from '@/models/MatchVersus';

export default {
    name: 'MatchVersus',
    props: {
        matchVersus: {
            type: MatchVersus,
            required: true,
        },

        /**
         * Color variant that will be applied to the winners' background.
         */
        variant: {
            type: String,
            default: 'primary',
        },

        /**
         * CSS class that will be applied to each team's `.col`.
         */
        teamClass: {
            type: [Array, Object, String],
            default: () => '',
        },

        /**
         * CSS class that will be applied to each participant's name.
         */
        nameClass: {
            type: [Array, Object, String],
            default: () => '',
        },
    },
    methods: {
        imageSide(index) {
            return (this.matchVersus.teams.length / 2) % (index + 1) === 0
                ? 'left'
                : 'right';
        },
    },
};
</script>

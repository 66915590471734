<template>
    <b-card no-body>
        <b-card-body>
            <b-row class="mb-2">
                <b-col>
                    <h3>{{ leagueMatchday.name }}</h3>
                </b-col>

                <b-col cols="auto">
                    <b-btn
                        v-b-toggle="`collapse-${leagueMatchday.id}`"
                        class="btn-circle"
                        variant="secondary"
                    >
                        <i :class="['fa', chevronClass]" />
                    </b-btn>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <form-group
                        v-if="scheduleEditable"
                        v-model="leagueMatchday.starts_at"
                        :error="leagueMatchday.errors.starts_at"
                        :label="$t('common.inputs.starts_at.label')"
                        :for="`${leagueMatchday.id}-starts_at`"
                        type="date"
                    />

                    <h6
                        v-else
                        class="text-primary"
                    >
                        {{ dateString }}
                    </h6>
                </b-col>

                <b-col>
                    <form-group
                        v-if="scheduleEditable"
                        v-model="leagueMatchday.location"
                        :placeholder="$t('common.inputs.location.placeholder')"
                        :error="leagueMatchday.errors.location"
                        :label="$t('common.inputs.location.label')"
                        :for="`${leagueMatchday.id}-location`"
                    />

                    <h6
                        v-else
                        class="text-primary text-right"
                    >
                        {{ leagueMatchday.location }}
                    </h6>
                </b-col>
            </b-row>
        </b-card-body>

        <b-collapse
            :id="`collapse-${leagueMatchday.id}`"
            v-model="visible"
            class="bg-light"
        >
            <b-card-body class="px-2 pb-1">
                <wait-for-resource :resource="leagueMatchday.league_matchday_groups">
                    <div
                        v-for="(lmg, lmgi) in leagueMatchdayGroups"
                        :key="lmg.id"
                        :class="{'mb-3': lmgi !== leagueMatchdayGroups.length - 1}"
                    >
                        <h6 class="text-muted text-uppercase ml-3 mb-3">
                            {{ lmg.league_group.name }}
                        </h6>

                        <team-match-versus
                            v-for="leagueTeamMatch in lmg.league_team_matches.models"
                            :key="leagueTeamMatch.id"
                            class="mb-2"
                            :team-match="leagueTeamMatch.team_match"
                            :expected-played-at="leagueMatchday.starts_at"
                            :score-editable="scoreEditable"
                            :contains-incorrect-matches="leagueTeamMatch.incorrect_team_match_schedules_count > 0"
                            @scores-verified="leagueTeamMatch.incorrect_team_match_schedules_count = 0"
                        />
                    </div>
                </wait-for-resource>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import {LeagueMatchday} from '@/models/LeagueMatchday';
import FormGroup from '@/components/common/form/Group';
import TeamMatchVersus from '@/components/teamMatches/Versus';

/**
 * The card representation of a LeagueMatchday.
 */
export default {
    name: 'LeagueMatchdayCard',
    components: {FormGroup, TeamMatchVersus},
    props: {
        leagueMatchday: {
            type: LeagueMatchday,
            required: true,
        },

        /**
         * If true, the matchday's `starts_at` and `location` can be edited.
         */
        scheduleEditable: {
            type: Boolean,
            default: false,
        },

        /**
         * If true, the match scores in the matchday can be edited. However, we
         * add extra check: the scores are only editable if the matchday has passed.
         */
        scoreEditable: {
            type: Boolean,
            default: false,
        },

        /**
         * If true, the collapse will be preopened.
         */
        preopen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fetched: false,
            visible: this.preopen,
        };
    },
    computed: {
        chevronClass() {
            return 'fa-chevron-' + (this.visible ? 'up' : 'down');
        },
        dateString() {
            let property;

            if (this.leagueMatchday.ends_at && this.leagueMatchday.starts_at) {
                if (this.leagueMatchday.ends_at.isSame(this.leagueMatchday.starts_at)) {
                    return this.$t('leagues.schedule.index.league_matchday_at', [
                        this.leagueMatchday.starts_at.format('DD-MM-YYYY'),
                    ]);
                } else {
                    return this.$t('leagues.schedule.index.league_matchday_between', [
                        this.leagueMatchday.starts_at.format('DD-MM-YYYY'),
                        this.leagueMatchday.ends_at.format('DD-MM-YYYY'),
                    ]);
                }
            }

            if (this.leagueMatchday.ends_at) {
                property = 'ends_at';
            } else if (this.leagueMatchday.starts_at) {
                property = 'starts_at';
            }

            if (!property) {
                return '';
            }

            return this.$t(
                'leagues.schedule.index.league_matchday_' + property, [
                    this.leagueMatchday[property].format('DD-MM-YYYY'),
                ]);
        },
        leagueMatchdayGroups() {
            return this.leagueMatchday.league_matchday_groups.models;
        },
    },
    watch: {
        visible: {
            handler: function(val) {
                if (val) this.fetchLeagueMatchdayGroups();
            },
            immediate: true,
        },
    },
    methods: {
        async fetchLeagueMatchdayGroups() {
            if (this.fetched) {
                return;
            }

            await this.leagueMatchday.fetchLeagueMatchdayGroups();

            this.fetched = true;
        },
    },
};
</script>

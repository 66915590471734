<template>
    <div>
        <portal to="layout-default-header">
            <div>
                <!-- Back Button -->
                <btn-icon
                    variant="primary"
                    icon="arrow-left"
                    class="mb-4"
                    @click="$router.back()"
                />

                <b-row align-v="end">
                    <b-col class="mb-3 mb-sm-0">
                        <h1 class="mb-0">
                            {{ $t('leagues.schedule.edit.title') }}
                        </h1>
                    </b-col>

                    <!-- Only show "generateMatchdays" or "save" button if the league has not started. -->
                    <b-col
                        v-if="!league.has_started"
                        class="text-right"
                    >
                        <btn-resource
                            v-if="league.status === LeagueStatus.WAITING_FOR_TEAMS"
                            class="btn-primary btn-min-w"
                            :resource="league"
                            :disabled="false"
                            @click="league.generateMatchdays()"
                        >
                            {{ $t('leagues.actions.generate_matchdays') }}
                        </btn-resource>

                        <btn-resource
                            v-if="league.status === LeagueStatus.WAITING_FOR_MATCHDAYS"
                            class="btn-primary btn-min-w"
                            :disabled="!league.changed() || !leagueMatchdays.changed()"
                            :resource="league"
                            @click="league.save()"
                        >
                            {{ $t('save') }}
                        </btn-resource>
                    </b-col>
                </b-row>
            </div>
        </portal>

        <!-- Show helpful instruction if the matchdays have not been generated. -->
        <div v-if="league.status === LeagueStatus.WAITING_FOR_TEAMS">
            <p>{{ $t('leagues.messages.can_generate_matchdays') }}</p>

            <b-btn
                v-t="'leagues.actions.input_teams'"
                :to="{name: 'leagues.teams.edit'}"
            />
        </div>

        <b-row
            v-else
            align-h="center"
        >
            <b-col
                lg="9"
                xl="8"
                class="px-0 px-md-3"
            >
                <wait-for-resource
                    class="card-stack"
                    :resource="leagueMatchdays"
                >
                    <!-- Show helpful instruction to verify scores for incorrect team matches. -->
                    <div
                        v-if="league.has_started"
                        class="text-center px-3 mb-4"
                    >
                        {{ $t('leagues.messages.verify_match_scores_instruction') }}
                    </div>

                    <!--
                    Schedule time and location can be edited if the league is `WAITING_FOR_MATCHDAYS`.
                    Match scores can be edited if the league has started.
                    -->
                    <league-matchday-card
                        v-for="leagueMatchday in leagueMatchdays.models"
                        :key="leagueMatchday.id"
                        :league-matchday="leagueMatchday"
                        :schedule-editable="league.status === LeagueStatus.WAITING_FOR_MATCHDAYS"
                        :score-editable="league.has_started"
                        :preopen="league.current_league_matchday
                            && leagueMatchday.id === league.current_league_matchday.id"
                    />
                </wait-for-resource>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {League} from '@/models/League';
import {LeagueMatchdays} from '@/models/LeagueMatchday';
import LeagueStatus from '@/library/enums/LeagueStatus';
import LeagueMatchdayCard from '@/components/leagueMatchdays/Card';

export default {
    name: 'LeaguesScheduleEdit',
    components: {LeagueMatchdayCard},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data() {
        return {
            LeagueStatus,
            leagueMatchdays: new LeagueMatchdays([], {
                routePrefix: this.league.getFetchURL(),
            }),
        };
    },
    async created() {
        // Only fetch the league matchdays if they have been generated.
        if (this.league.status !== LeagueStatus.WAITING_FOR_TEAMS) {
            await this.leagueMatchdays.fetch();

            // Link the fetched matchdays with the league, so the league can be
            // saved with the updated matchdays.
            this.league.league_matchdays = this.leagueMatchdays;
        }
    },
};
</script>

<template>
    <match-versus
        v-bind="$attrs"
        :match-versus="schedule.toMatchVersus(teamMatch)"
    >
        <!-- Match Score -->
        <template
            v-if="!!schedule.match.score"
            #absolute-center
        >
            <div class="d-flex align-items-center">
                <i
                    v-if="scoreEditable"
                    class="arrow fa fa-chevron-left fa-sm mr-2"
                    :class="{
                        'disabled': !scoreCanGo(-1),
                        'text-white': scoreIndex <= 0,
                    }"
                    @click="incrementScore(-1)"
                />

                <div class="bg-white border rounded py-1 px-3">
                    {{ schedule.match.score }}
                </div>

                <i
                    v-if="scoreEditable"
                    class="arrow fa fa-chevron-right fa-sm ml-2"
                    :class="{
                        'disabled': !scoreCanGo(1),
                        'text-white': scoreIndex >= 0,
                    }"
                    @click="incrementScore(1)"
                />
            </div>
        </template>
    </match-versus>
</template>

<script>
import {Match} from '@/models/Match';
import {TeamMatch} from '@/models/TeamMatch';
import MatchVersus from '@/components/common/MatchVersus';
import {TeamMatchSchedule} from '@/models/TeamMatchSchedule';

/**
 * Versus representation of a TeamMatchSchedule instance. It's similar to a
 * MatchVersus component but with editable match score functionality.
 */
export default {
    name: 'TeamMatchScheduleVersus',
    components: {MatchVersus},
    props: {
        schedule: {
            type: TeamMatchSchedule,
            required: true,
        },

        teamMatch: {
            type: TeamMatch,
            required: true,
        },

        /**
         * If true, the match score of the schedule can be edited.
         */
        scoreEditable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            scoreIndex: 0,
        };
    },
    computed: {
        /**
         * An array of IDs of teams that plays in the team match.
         */
        teamIds() {
            return this.teamMatch.team_match_teams.map(tmt => tmt.team.id);
        },

        /**
         * An array of possible scores of a golf match.
         */
        possibleScores() {
            return Match.possibleScores;
        },
    },
    created() {
        // If the score is editable but the match doesn't have any score yet,
        // we will set the score to 'AS'.
        if (this.scoreEditable && !this.schedule.match.score) {
            this.setScore();
        }

        if (this.schedule.match.score && this.schedule.winning_team_id) {
            const scoreIndex = Match.possibleScores
                .findIndex(s => s === this.schedule.match.score)
                || 0;

            const winIndex = this.teamIds
                .findIndex(id => id === this.schedule.winning_team_id);

            this.scoreIndex = scoreIndex * (winIndex === 0 ? -1 : 1);
        }
    },
    methods: {
        /**
         * Sets the schedule's match score according to the `scoreIndex`.
         * This will also update the schedule's `winning_team_id`.
         */
        setScore() {
            let winning_team_id;

            // The scoreIndex indicates which team is winning ...
            if (this.scoreIndex === 0) {
                // 0 scoreIndex means the match is 'AS' (All Square / draw) ...
                winning_team_id = null;
            } else if (this.scoreIndex < 0) {
                // Negative scoreIndex means the left team is winning ...
                winning_team_id = this.teamIds[0];
            } else if (this.scoreIndex > 0) {
                // Positive scoreIndex means the right team is winning ...
                winning_team_id = this.teamIds[1];
            }

            this.$set(this.schedule, 'winning_team_id', winning_team_id);

            // Update the schedule's match score.
            const newScore = this.possibleScores[Math.abs(this.scoreIndex)];

            if (newScore) this.schedule.match.score = newScore;
        },

        /**
         * Increments the score towards the left or right team.
         * If the step is -1, the score is incremented towards the left team.
         * If the step is +1, the score is incremented towards the right team.
         */
        incrementScore(step) {
            if (this.scoreCanGo(step)) {
                this.scoreIndex += step;

                this.setScore();
            }
        },

        /**
         * Determines if the score can be incremented even more left or right.
         */
        scoreCanGo(step) {
            const newIndex = this.scoreIndex + step;

            return Math.abs(newIndex) < this.possibleScores.length;
        },
    },
};
</script>

<style lang="scss" scoped>
.arrow {
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: $btn-disabled-opacity;
    }
}
</style>
